/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOCATION_DETAILS: '/:locationId',
  TECHNICAL_ADVISORS: '/technical-advisors',
  MACHINES: '/machines',
  MACHINE_DETAILS: 'machines/:machineID',
  INSPECTION_OVERSIGHT: '/inspection-oversight',
  INSPECTION_OVERSIGHT_DETAILS: '/inspection-oversight/:inspectionID',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password'
};

export const API_ROUTES = {
  ME: 'common/users/me?isAdmin=true',
  FILE_SIGNED_URL: 'common/get-file-sign-url',
  UPLOAD_IMAGE: 'common/upload/verification/file',
  ALL_LOCATIONS: 'location/locations',
  SINGLE_LOCATION: 'location/id',
  LOCATION_MACHINES: 'machine/machines/locationId',
  DELETE_LOCATION: 'location/remove',
  DELETE_MACHINE: 'machine/remove',
  ALL_ADVISORS: 'advisor/advisors',
  ALL_MACHINES: 'machine/machines',
  SINGLE_MACHINE: 'machine/id',
  ALL_INSPECTIONS: 'inspection/inspections',
  SINGLE_INSPECTION: 'inspection/id',
  CREATE_LOCATION: 'location',
  UPDATE_LOCATION: 'location/update',
  LOCATION_HISTORIES: 'location/locations/histories',
  CREATE_ADVISOR: 'advisor',
  UPDATE_ADVISOR: 'advisor/update',
  DELETE_ADVISOR: 'advisor/remove',
  SINGLE_ADVISOR: 'advisor/id',
  CREATE_MACHINE: 'machine',
  UPDATE_MACHINE: 'machine/update'
};

export const API_ERROR = {
  GENERIC_ERROR: 'Something went wrong'
};

export const GLOBAL_PAGINATION_LIMIT = 12;
export const TABLE_PAGINATION_LIMIT = 10;
export const HISTORY_PAGINATION_LIMIT = 5;
export const customFormat = 'HH:mm:ss | DD/MM/YYYY';

export const ExcelAllowedTypes = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL:
    /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/
};

export const FREQUENCY = [
  {
    id: 1,
    label: 'MONTHLY',
    value: 'MONTHLY'
  },
  {
    id: 2,
    label: 'QUARTERLY',
    value: 'QUARTERLY'
  },
  {
    id: 3,
    label: 'YEARLY',
    value: 'YEARLY'
  }
];

export const US_CITIES = [
  {
    value: 'Alabama',
    label: 'Alabama'
  },
  {
    value: 'Alaska',
    label: 'Alaska'
  },
  {
    value: 'American Samoa',
    label: 'American Samoa'
  },
  {
    value: 'Arizona',
    label: 'Arizona'
  },
  {
    value: 'Arkansas',
    label: 'Arkansas'
  },
  {
    value: 'California',
    label: 'California'
  },
  {
    value: 'Colorado',
    label: 'Colorado'
  },
  {
    value: 'Connecticut',
    label: 'Connecticut'
  },
  {
    value: 'Delaware',
    label: 'Delaware'
  },
  {
    value: 'District Of Columbia',
    label: 'District Of Columbia'
  },
  {
    value: 'Federated States Of Micronesia',
    label: 'Federated States Of Micronesia'
  },
  {
    value: 'Florida',
    label: 'Florida'
  },
  {
    value: 'Georgia',
    label: 'Georgia'
  },
  {
    value: 'Guam',
    label: 'Guam'
  },
  {
    value: 'Hawaii',
    label: 'Hawaii'
  },
  {
    value: 'Idaho',
    label: 'Idaho'
  },
  {
    value: 'Illinois',
    label: 'Illinois'
  },
  {
    value: 'Indiana',
    label: 'Indiana'
  },
  {
    value: 'Iowa',
    label: 'Iowa'
  },
  {
    value: 'Kansas',
    label: 'Kansas'
  },
  {
    value: 'Kentucky',
    label: 'Kentucky'
  },
  {
    value: 'Louisiana',
    label: 'Louisiana'
  },
  {
    value: 'Maine',
    label: 'Maine'
  },
  {
    value: 'Marshall Islands',
    label: 'Marshall Islands'
  },
  {
    value: 'Maryland',
    label: 'Maryland'
  },
  {
    value: 'Massachusetts',
    label: 'Massachusetts'
  },
  {
    value: 'Michigan',
    label: 'Michigan'
  },
  {
    value: 'Minnesota',
    label: 'Minnesota'
  },
  {
    value: 'Mississippi',
    label: 'Mississippi'
  },
  {
    value: 'Missouri',
    label: 'Missouri'
  },
  {
    value: 'Montana',
    label: 'Montana'
  },
  {
    value: 'Nebraska',
    label: 'Nebraska'
  },
  {
    value: 'Nevada',
    label: 'Nevada'
  },
  {
    value: 'New Hampshire',
    label: 'New Hampshire'
  },
  {
    value: 'New Jersey',
    label: 'New Jersey'
  },
  {
    value: 'New Mexico',
    label: 'New Mexico'
  },
  {
    value: 'New York',
    label: 'New York'
  },
  {
    value: 'North Carolina',
    label: 'North Carolina'
  },
  {
    value: 'North Dakota',
    label: 'North Dakota'
  },
  {
    value: 'Northern Mariana Islands',
    label: 'Northern Mariana Islands'
  },
  {
    value: 'Ohio',
    label: 'Ohio'
  },
  {
    value: 'Oklahoma',
    label: 'Oklahoma'
  },
  {
    value: 'Oregon',
    label: 'Oregon'
  },
  {
    value: 'Palau',
    label: 'Palau'
  },
  {
    value: 'Pennsylvania',
    label: 'Pennsylvania'
  },
  {
    value: 'Puerto Rico',
    label: 'Puerto Rico'
  },
  {
    value: 'Rhode Island',
    label: 'Rhode Island'
  },
  {
    value: 'South Carolina',
    label: 'South Carolina'
  },
  {
    value: 'South Dakota',
    label: 'South Dakota'
  },
  {
    value: 'Tennessee',
    label: 'Tennessee'
  },
  {
    value: 'Texas',
    label: 'Texas'
  },
  {
    value: 'Utah',
    label: 'Utah'
  },
  {
    value: 'Vermont',
    label: 'Vermont'
  },
  {
    value: 'Virgin Islands',
    label: 'Virgin Islands'
  },
  {
    value: 'Virginia',
    label: 'Virginia'
  },
  {
    value: 'Washington',
    label: 'Washington'
  },
  {
    value: 'West Virginia',
    label: 'West Virginia'
  },
  {
    value: 'Wisconsin',
    label: 'Wisconsin'
  },
  {
    value: 'Wyoming',
    label: 'Wyoming'
  }
];

export const US_STATES = [
  { value: 'Alabama', label: 'Alabama' },
  { value: 'Alaska', label: 'Alaska' },
  { value: 'Arizona', label: 'Arizona' },
  { value: 'Arkansas', label: 'Arkansas' },
  { value: 'California', label: 'California' },
  { value: 'Colorado', label: 'Colorado' },
  { value: 'Connecticut', label: 'Connecticut' },
  { value: 'Delaware', label: 'Delaware' },
  { value: 'Florida', label: 'Florida' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Hawaii', label: 'Hawaii' },
  { value: 'Idaho', label: 'Idaho' },
  { value: 'Illinois', label: 'Illinois' },
  { value: 'Indiana', label: 'Indiana' },
  { value: 'Iowa', label: 'Iowa' },
  { value: 'Kansas', label: 'Kansas' },
  { value: 'Kentucky', label: 'Kentucky' },
  { value: 'Louisiana', label: 'Louisiana' },
  { value: 'Maine', label: 'Maine' },
  { value: 'Maryland', label: 'Maryland' },
  { value: 'Massachusetts', label: 'Massachusetts' },
  { value: 'Michigan', label: 'Michigan' },
  { value: 'Minnesota', label: 'Minnesota' },
  { value: 'Mississippi', label: 'Mississippi' },
  { value: 'Missouri', label: 'Missouri' },
  { value: 'Montana', label: 'Montana' },
  { value: 'Nebraska', label: 'Nebraska' },
  { value: 'Nevada', label: 'Nevada' },
  { value: 'New Hampshire', label: 'New Hampshire' },
  { value: 'New Jersey', label: 'New Jersey' },
  { value: 'New Mexico', label: 'New Mexico' },
  { value: 'New York', label: 'New York' },
  { value: 'North Carolina', label: 'North Carolina' },
  { value: 'North Dakota', label: 'North Dakota' },
  { value: 'Ohio', label: 'Ohio' },
  { value: 'Oklahoma', label: 'Oklahoma' },
  { value: 'Oregon', label: 'Oregon' },
  { value: 'Pennsylvania', label: 'Pennsylvania' },
  { value: 'Rhode Island', label: 'Rhode Island' },
  { value: 'South Carolina', label: 'South Carolina' },
  { value: 'South Dakota', label: 'South Dakota' },
  { value: 'Tennessee', label: 'Tennessee' },
  { value: 'Texas', label: 'Texas' },
  { value: 'Utah', label: 'Utah' },
  { value: 'Vermont', label: 'Vermont' },
  { value: 'Virginia', label: 'Virginia' },
  { value: 'Washington', label: 'Washington' },
  { value: 'West Virginia', label: 'West Virginia' },
  { value: 'Wisconsin', label: 'Wisconsin' },
  { value: 'Wyoming', label: 'Wyoming' },
  { value: 'District of Columbia', label: 'District of Columbia' },
  { value: 'Puerto Rico', label: 'Puerto Rico' },
  { value: 'Guam', label: 'Guam' },
  { value: 'American Samoa', label: 'American Samoa' },
  { value: 'U.S. Virgin Islands', label: 'U.S. Virgin Islands' },
  { value: 'Northern Mariana Islands', label: 'Northern Mariana Islands' }
];

export const MACHINE_TYPES = [
  { value: 'Optimum 3 Cardinal', label: 'Optimum 3 Cardinal' },
  { value: 'Fusion 5 32" banilla', label: 'Fusion 5 32" banilla' },
  { value: 'Skyriser', label: 'Skyriser' },
  { value: 'Aurora Link 32" banilla', label: 'Aurora Link 32" banilla' },
  { value: 'Fusion 6 IP', label: 'Fusion 6 IP' },
  { value: 'Fusion 4 43" hydro', label: 'Fusion 4 43" hydro' },
  { value: 'Bad Dog IP', label: 'Bad Dog IP' },
  {
    value: 'Twin spin wood single screen',
    label: 'Twin spin wood single screen'
  },
  { value: 'Platinum 3 Dual Hydo 23"', label: 'Platinum 3 Dual Hydo 23"' },
  {
    value: 'Diamond Skills 8 Dual hydo 28"',
    label: 'Diamond Skills 8 Dual hydo 28"'
  },
  { value: 'Aurora 1 32" banilla', label: 'Aurora 1 32" banilla' },
  {
    value: 'The best of Nudge Prime dual',
    label: 'The best of Nudge Prime dual'
  },
  { value: 'America Dream Rainbow', label: 'America Dream Rainbow' },
  { value: 'Patinum 2 Dual IP', label: 'Patinum 2 Dual IP' },
  { value: 'Aurura 3 43"IP', label: 'Aurura 3 43"IP' },
  { value: 'World famous 43" IP', label: 'World famous 43" IP' },
  { value: 'Bad Dog 43" IP', label: 'Bad Dog 43" IP' },
  { value: 'Holiday Special 43" hydro', label: 'Holiday Special 43" hydro' },
  { value: 'Fusion 5 43" hydro', label: 'Fusion 5 43" hydro' },
  {
    value: 'Best Classic Dual 28 " hydro',
    label: 'Best Classic Dual 28 " hydro'
  },
  { value: 'Favour Skills 2 28" hydro', label: 'Favour Skills 2 28" hydro' },
  { value: 'Fusion link 32" Banilla', label: 'Fusion link 32" Banilla' },
  {
    value: 'Favorite Skills 2 Dual Zydexo',
    label: 'Favorite Skills 2 Dual Zydexo'
  },
  {
    value: 'Aurora Link 43" Zydexo Joy Stick',
    label: 'Aurora Link 43" Zydexo Joy Stick'
  },
  { value: 'Bag Dog Bad Dog', label: 'Bag Dog Bad Dog' },
  { value: 'FortKnox IGT', label: 'FortKnox IGT' },
  { value: 'Best Classic Dual primero', label: 'Best Classic Dual primero' },
  { value: 'Cadillac 2 Primero 43"', label: 'Cadillac 2 Primero 43"' },
  { value: 'Fusion 4 32" banilla', label: 'Fusion 4 32" banilla' },
  { value: 'Bad Dog 43" Bad dog', label: 'Bad Dog 43" Bad dog' },
  { value: 'Best Classic Rainbow Dual', label: 'Best Classic Rainbow Dual' },
  { value: 'FortKnox 2', label: 'FortKnox 2' },
  {
    value: 'Diamond Skills I Dual hydo 23"',
    label: 'Diamond Skills I Dual hydo 23"'
  },
  { value: 'Holiday Special 32" Elite', label: 'Holiday Special 32" Elite' },
  { value: 'Funny Town Rainbow', label: 'Funny Town Rainbow' },
  { value: 'Platinum 2 Dual Hydo 28"', label: 'Platinum 2 Dual Hydo 28"' },
  { value: 'Bad dog 43"Bad Dog', label: 'Bad dog 43"Bad Dog' },
  {
    value: 'Favorite Skill 2 Banilla Dual 28"',
    label: 'Favorite Skill 2 Banilla Dual 28"'
  },
  {
    value: 'Platinum 2 banilla Dual 28"',
    label: 'Platinum 2 banilla Dual 28"'
  },
  { value: 'Optimum 2 Cardinal', label: 'Optimum 2 Cardinal' },
  { value: 'Aurora 3 43" Elite', label: 'Aurora 3 43" Elite' },
  { value: 'Aurora Link 43" IP', label: 'Aurora Link 43" IP' },
  {
    value: 'Diamond Skill 8 Single Screen Primero',
    label: 'Diamond Skill 8 Single Screen Primero'
  },
  { value: 'Aurora / 32" Elite', label: 'Aurora / 32" Elite' },
  { value: 'Fusion 5 43" IP', label: 'Fusion 5 43" IP' },
  {
    value: 'Favorite Skills 2 Dual banilla 28"',
    label: 'Favorite Skills 2 Dual banilla 28"'
  },
  { value: 'The Price is Right IGT', label: 'The Price is Right IGT' },
  {
    value: 'American Dream Dual Banilla',
    label: 'American Dream Dual Banilla'
  },
  { value: 'Platinum 3 Dual Banilla', label: 'Platinum 3 Dual Banilla' },
  {
    value: 'Tip top Classic Dual Banilla',
    label: 'Tip top Classic Dual Banilla'
  },
  { value: 'Fusion / 32" banilla', label: 'Fusion / 32" banilla' },
  {
    value: 'World famous 23" Dual Primero',
    label: 'World famous 23" Dual Primero'
  },
  {
    value: 'Favorite Skills 23" Dual Primero',
    label: 'Favorite Skills 23" Dual Primero'
  },
  {
    value: 'Diomand Nudge fap 4ory Dual 28" hydro',
    label: 'Diomand Nudge fap 4ory Dual 28" hydro'
  },
  { value: 'Aurora /43" hydro', label: 'Aurora /43" hydro' },
  { value: 'Fanstasy Lane Rainbow', label: 'Fanstasy Lane Rainbow' },
  {
    value: 'Funny town Nudge Dual Banilla',
    label: 'Funny town Nudge Dual Banilla'
  },
  { value: 'Holiday Special /43" IP', label: 'Holiday Special /43" IP' },
  {
    value: 'Tip top Classic Dual/ Old  Banilla 23"',
    label: 'Tip top Classic Dual/ Old  Banilla 23"'
  },
  { value: 'Platinum /Dual primero 23"', label: 'Platinum /Dual primero 23"' },
  { value: 'Fusion 6 43" IP', label: 'Fusion 6 43" IP' },
  { value: 'Platinum 2 Dual Omega', label: 'Platinum 2 Dual Omega' },
  { value: 'Aurora / 43" Elite', label: 'Aurora / 43" Elite' },
  { value: 'Aurora Link 43" hydro', label: 'Aurora Link 43" hydro' },
  { value: 'Tip Top Classic Rainbow', label: 'Tip Top Classic Rainbow' },
  { value: 'Fusion 4 43" hydro', label: 'Fusion 4 43" hydro' },
  { value: 'Bar X Primero', label: 'Bar X Primero' },
  { value: 'Fox + Knox IGT', label: 'Fox + Knox IGT' },
  {
    value: 'Tip Top Classic Dual Hydro banilla 28"',
    label: 'Tip Top Classic Dual Hydro banilla 28"'
  },
  { value: 'Best Classic Rainbow', label: 'Best Classic Rainbow' },
  { value: 'Aurora 3 Zydexo 32"', label: 'Aurora 3 Zydexo 32"' },
  {
    value: 'Aurora 3 Zydexo joystick 43"',
    label: 'Aurora 3 Zydexo joystick 43"'
  },
  { value: 'Platinum 2 dual 28" hydro', label: 'Platinum 2 dual 28" hydro' },
  {
    value: 'World Famous Dual hydro 28"',
    label: 'World Famous Dual hydro 28"'
  },
  { value: 'Fusion 43" hydro', label: 'Fusion 43" hydro' },
  {
    value: 'Nudge for fan banilla dual 23"',
    label: 'Nudge for fan banilla dual 23"'
  },
  { value: 'Super Mix Nudge Rainbow', label: 'Super Mix Nudge Rainbow' },
  { value: 'Fusion 6 43" hydro', label: 'Fusion 6 43" hydro' },
  { value: 'Cadillac 2 32" Primero', label: 'Cadillac 2 32" Primero' }
];

export const ACCESS_RIGHTS = [
  {
    label: 'ADMIN',
    value: 'ADMIN'
  }
];
